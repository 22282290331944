import "./styles.css";
import React from "react";
import Menu from "../menu";
import LogoDirecty from "../../../assets/brand/directy - logo principal.png";
import { useTranslation } from "react-i18next";
import bandeiraBrasil from "../../../assets/images/brasil.png";
import bandeiraEua from "../../../assets/images/eua.png";
import { useLocation, Link } from "react-router-dom";
import userFoto from "../../../assets/images/userFoto.png";

function Cabecalho({ usuario }) {
    const { t, i18n } = useTranslation();

    const availableLanguages = [
        { label: "Português - Brasil", value: "pt", bandeira: bandeiraBrasil, pais: "Brasil" },
        { label: "English - US", value: "en", bandeira: bandeiraEua, pais: "US" },
    ];

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const nomeUsuario = usuario && usuario.nome ? usuario.nome : t("Usuário");

    const activeLng = i18n.language;

    const location = useLocation();
    const locationPathBase = location.pathname.slice(1).split("/")[0];
    const pageName = locationPathBase || "home";

    return (
        <div id="cabecalhoComponent">
            <div className="itensHolder">
                <div className="perfilHolder">
                    <Link to={"/perfil"} className="linkPerfil">
                        <img src={userFoto} alt={t("foto representativa do usuário")} />
                    </Link>

                    <div className="welcomeHolder">
                        <p>{t("Bem vindo usuário", { user: nomeUsuario })}</p>
                        <p>
                            <span>{t("Directy Desafios")}</span>
                            <span>
                                {"- "}
                                {t("pageTitle_" + pageName)}
                            </span>
                        </p>
                    </div>
                </div>

                <Link to={"/"} className="linkLogo">
                    <img src={LogoDirecty} alt={t("logo da empresa Directy")} />
                </Link>

                <div className="languagesHolder">
                    {availableLanguages.map(
                        (
                            {
                                label: lngLabel,
                                value: lngValue,
                                bandeira: lngBandeira,
                                pais: lngPais,
                            },
                            i
                        ) => {
                            return (
                                <button
                                    key={i}
                                    onClick={() => {
                                        changeLanguage(lngValue);
                                    }}
                                >
                                    <div>
                                        <img
                                            src={lngBandeira}
                                            alt={t("bandeira do país", {
                                                country: lngPais,
                                            })}
                                            title={lngLabel}
                                        />
                                    </div>
                                    <span className={lngValue === activeLng ? "active" : ""}>
                                        {lngLabel.split(" ")[0]}
                                    </span>
                                </button>
                            );
                        }
                    )}
                </div>
            </div>

            <div className="menuHolder">
                <Menu />
            </div>
        </div>
    );
}

export default Cabecalho;
