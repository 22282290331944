import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillStar } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../setup/api";
import "./styles.css";

function calcTime(t1, t2) {
    const timeDifferenceMilliseconds = t1.getTime() - t2.getTime();
    const hoursDifference = timeDifferenceMilliseconds / 3600000;
    const roundedHours = Math.floor(hoursDifference);

    return roundedHours;
}

function MensagemCentral({ mensagem }) {
    return <h2>{mensagem}</h2>;
}

function TelaDesafio() {
    const { t } = useTranslation();

    const navegar = useNavigate();

    const { realizadoId } = useParams();

    const [desafio, setDesafio] = useState();
    const [envio, setEnvio] = useState("");
    const [feedback, setFeedback] = useState("");

    const mensagemCarregando = t("Carregando Desafios...");

    useEffect(() => {
        const mensagemNenhum = t("Nenhum desafio com esse ID encontrado");

        Api.GetDesafioId(realizadoId)
            .then((res) => {
                let desaf = { ...res };

                desaf.habilidades = desaf.habilidades.split(";").sort();
                desaf.horario = desaf.horario ? new Date(desaf.horario) : null;
                desaf.prazo = desaf.prazo ? new Date(desaf.prazo) : null;
                desaf.tempoRestante = desaf.prazo ? calcTime(desaf.prazo, new Date()) : null;

                setDesafio(desaf);
                setEnvio(desaf.github || "");
                setFeedback(desaf.feedback || "");
            })
            .catch(() => {
                alert(mensagemNenhum);
                navegar(-1);
            });
    }, [t, navegar, realizadoId]);

    const enviarRealizado = (e) => {
        e.preventDefault();

        Api.PutRealizado(realizadoId, envio, feedback).then((res) => {
            alert("Envio realizado com sucesso!");
            navegar("/desafios");
        });
    };

    return (
        <div id="TelaDesafio">
            {!desafio && <MensagemCentral mensagem={mensagemCarregando} />}

            {desafio && (
                <div className="CaixaDesafio">
                    <h3>{desafio.titulo}</h3>
                    <p className="descricaoHolder">
                        <span>{t("Descrição")}:</span>
                        <span>{desafio.descricao}</span>
                    </p>
                    <div className="habilidadesHolder">
                        <span>{t("Habilidades")}:</span>
                        <ul>
                            {desafio.habilidades.map((hab, i) => (
                                <li key={i}>{hab}</li>
                            ))}
                        </ul>
                    </div>
                    <p className="dificuldadeHolder">
                        <span>{t("Dificuldade")}:</span>
                        <span className="starsHolder">
                            {new Array(10).fill().map((s, i) => (
                                <AiFillStar
                                    className={i + 1 <= desafio.dificuldade ? "star fill" : "star"}
                                    key={i}
                                />
                            ))}
                        </span>
                    </p>
                    <p className="enviadoHolder">
                        <span>{t("Enviado")}:</span>
                        <span className="enviadoIcon">
                            {desafio.github && desafio.horario ? (
                                <>
                                    <AiFillCheckCircle className="check" />
                                    <span>{desafio.horario.toLocaleString()}</span>
                                </>
                            ) : (
                                <AiFillCloseCircle className="uncheck" />
                            )}
                        </span>
                    </p>
                    {desafio.prazo && (
                        <p className="prazoHolder">
                            <span>{t("Prazo")}:</span>
                            <span>
                                {desafio.prazo.toLocaleString()} (
                                {t("Horas restantes", { horas: desafio.tempoRestante })})
                            </span>
                        </p>
                    )}

                    <a href={desafio.documento}>{t("Baixar requisitos do teste")}</a>

                    <div className="divisoria"></div>

                    <form onSubmit={enviarRealizado}>
                        <label>
                            <span>{t("Link para o seu repositório GitHub")}</span>
                            <input
                                type={"text"}
                                value={envio}
                                onChange={(e) => {
                                    setEnvio(e.target.value);
                                }}
                            />
                        </label>

                        <label>
                            <span>{t("Seu feedback do teste")}</span>
                            <textarea
                                type={"text"}
                                value={feedback}
                                onChange={(e) => {
                                    setFeedback(e.target.value);
                                }}
                                rows={6}
                            />
                        </label>

                        <button type="submit">{t("Enviar")}</button>
                    </form>
                </div>
            )}
        </div>
    );
}

export default TelaDesafio;
