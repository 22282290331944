import "./styles.css";
import React from "react";
import { useState } from "react";
import { HiMenu } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { BsHouse } from "react-icons/bs";
import { IoExitOutline } from "react-icons/io5";
import { BsCardChecklist } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

function Menu() {
    const { t } = useTranslation();

    const navegar = useNavigate();

    const [openMenu, setOpenMenu] = useState(false);

    const linkItens = [
        {
            icon: <BsHouse />,
            titulo: "home",
            link: "/",
        },
        {
            icon: <BsCardChecklist />,
            titulo: "desafios",
            link: "/desafios",
        },
        {
            icon: <CgProfile />,
            titulo: "perfil",
            link: "/perfil",
        },
    ];

    const sairItem = {
        icon: <IoExitOutline />,
        titulo: "sair",
        link: "/login",
    };

    const deslogar = () => {
        if (window.confirm(t("Deseja realmente sair?"))) {
            sessionStorage.removeItem("loginCandidato");
            navegar("/login");
        }
    };

    return (
        <div
            id="menuComponent"
            tabIndex={0}
            onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    setOpenMenu(false);
                }
            }}
        >
            <button
                className={`menuIcon ${!openMenu && "close"}`}
                onClick={() => {
                    setOpenMenu((open) => !open);
                }}
            >
                <span className="menuSpanIcon">{openMenu ? <IoClose /> : <HiMenu />}</span>
                <span className="menuSpanFechar">{t("fecharMenu")}</span>
            </button>

            <div className={`menuItens ${!openMenu && "close"}`}>
                {linkItens.map(({ icon, titulo, link }, i) => {
                    const menuItemLink = "menuLinkItem_" + titulo;

                    return (
                        <NavLink
                            key={i}
                            to={link}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <span>{icon}</span>
                            <span>{t(menuItemLink)}</span>
                        </NavLink>
                    );
                })}

                <button className={"sairButton"} onClick={deslogar}>
                    <span>{sairItem.icon}</span>
                    <span>{t("menuLinkItem_" + sairItem.titulo)}</span>
                </button>
            </div>
        </div>
    );
}

export default Menu;
