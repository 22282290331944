import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BaseLogin from "./pages/baseLogin";
import BaseHome from "./pages/baseHome";
import Login from "./pages/login";
import TelaHome from "./pages/home";
import TelaDesafios from "./pages/desafios";
import TelaDesafio from "./pages/desafio";
import TelaPerfil from "./pages/perfil";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route element={<BaseLogin />}>
                        <Route path="/login" element={<Login />} />
                        <Route path="/novaSenha" element={<div>teste</div>} />

                        <Route path="/admin/login" element={<Login />} />
                        <Route path="/admin/novaSenha" element={<div>teste</div>} />
                    </Route>

                    <Route element={<BaseHome />}>
                        <Route path="/" element={<TelaHome />} />
                        <Route path="/home" element={<div>teste</div>} />
                        <Route path="/desafios" element={<TelaDesafios />} />
                        <Route path="/desafios/:realizadoId" element={<TelaDesafio />} />
                        <Route path="/perfil" element={<TelaPerfil />} />

                        <Route path="/admin/" element={<div>teste</div>} />
                        <Route path="/admin/desafios" element={<div>teste</div>} />
                        <Route path="/admin/desafios/novo" element={<div>teste</div>} />
                        <Route path="/admin/desafios/:desafioId" element={<div>teste</div>} />
                        <Route path="/admin/candidatos" element={<div>teste</div>} />
                        <Route path="/admin/candidatos/novo" element={<div>teste</div>} />
                        <Route path="/admin/candidatos/:candidatoId" element={<div>teste</div>} />
                        <Route path="/admin/candidatos/realizados" element={<div>teste</div>} />
                        <Route
                            path="/admin/candidatos/realizados/:realizadoId"
                            element={<div>teste</div>}
                        />
                        <Route path="/admin/gestores" element={<div>teste</div>} />
                        <Route path="/admin/gestores/novo" element={<div>teste</div>} />
                        <Route path="/admin/gestores/:gestorId" element={<div>teste</div>} />
                        <Route path="/admin/perfil" element={<div>teste</div>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
