import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../setup/api";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillStar } from "react-icons/ai";
import "./styles.css";

function calcTime(t1, t2) {
    const timeDifferenceMilliseconds = t1.getTime() - t2.getTime();
    const hoursDifference = timeDifferenceMilliseconds / 3600000;
    const roundedHours = Math.floor(hoursDifference);

    return roundedHours;
}

function CaixaDesafio({ desafio }) {
    const { t } = useTranslation();

    const titulo = desafio.titulo;
    const descricao = desafio.descricao;
    const habilidades = desafio.habilidades.split(";").sort();
    const dificuldade = desafio.dificuldade;

    const realizadoId = desafio.realizadoId;
    const github = desafio.github;
    const horario = desafio.horario ? new Date(desafio.horario) : null;
    const prazo = desafio.prazo ? new Date(desafio.prazo) : null;

    const tempoRestante = prazo ? calcTime(prazo, new Date()) : null;

    return (
        <Link className="CaixaDesafio" to={`/desafios/${realizadoId}`}>
            <h3>{titulo}</h3>
            <p className="descricaoHolder">
                <span>{t("Descrição")}:</span>
                <span>{descricao}</span>
            </p>
            <div className="habilidadesHolder">
                <span>{t("Habilidades")}:</span>
                <ul>
                    {habilidades.map((hab, i) => (
                        <li key={i}>{hab}</li>
                    ))}
                </ul>
            </div>
            <p className="dificuldadeHolder">
                <span>{t("Dificuldade")}:</span>
                <span className="starsHolder">
                    {new Array(10).fill().map((s, i) => (
                        <AiFillStar
                            className={i + 1 <= dificuldade ? "star fill" : "star"}
                            key={i}
                        />
                    ))}
                </span>
            </p>
            <p className="enviadoHolder">
                <span>{t("Enviado")}:</span>
                <span className="enviadoIcon">
                    {github && horario ? (
                        <>
                            <AiFillCheckCircle className="check" />
                            <span>{horario.toLocaleString()}</span>
                        </>
                    ) : (
                        <AiFillCloseCircle className="uncheck" />
                    )}
                </span>
            </p>
            {prazo && (
                <p className="prazoHolder">
                    <span>{t("Prazo")}:</span>
                    <span>
                        {prazo.toLocaleString()} ({t("Horas restantes", { horas: tempoRestante })})
                    </span>
                </p>
            )}

            <div className="CaixaDesafioButton">
                {t("Clique no card para mais informações e envio do teste")}
            </div>
        </Link>
    );
}

function GridDesafios({ desafios }) {
    return (
        <div id="GridDesafios">
            {desafios.map((desaf, i) => (
                <CaixaDesafio desafio={desaf} key={i} />
            ))}
        </div>
    );
}

function MensagemCentral({ mensagem }) {
    return <h2>{mensagem}</h2>;
}

function TelaDesafios() {
    const { t } = useTranslation();
    const navegar = useNavigate();

    const [desafios, setDesafios] = useState();

    useEffect(() => {
        Api.GetDesafiosCandidato()
            .then((data) => {
                setDesafios(data);
            })
            .catch((err) => {
                console.log(err);
                alert("erro");
                navegar("/login");
            });
    }, [navegar]);

    const mensagemCarregando = t("Carregando Desafios...");
    const mensagemNenhum = t("Nenhum desafio cadastrado para o seu usuário");

    return (
        <div id="TelaDesafios">
            {desafios ? (
                desafios.length > 0 ? (
                    <GridDesafios desafios={desafios} />
                ) : (
                    <MensagemCentral mensagem={mensagemNenhum} />
                )
            ) : (
                <MensagemCentral mensagem={mensagemCarregando} />
            )}
        </div>
    );
}

export default TelaDesafios;
