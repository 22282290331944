import "./styles.css";
import React, { useState } from "react";
import LogoDirecty from "../../assets/brand/directy - logo principal.png";
import { useNavigate } from "react-router-dom";
import Api from "../../setup/api";

function Login() {
    const navegar = useNavigate();

    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");

    const salvarLogin = (loginCandidato) => {
        sessionStorage.setItem("loginCandidato", JSON.stringify(loginCandidato));
    };

    const submitLogin = (e) => {
        e.preventDefault();

        Api.LoginCandidato(email, senha)
            .then((data) => {
                salvarLogin(data);
                navegar("/");
            })
            .catch((err) => {
                if (!err.response) {
                    console.log(err);
                    alert("erro");
                    return;
                }

                let resp = err.response;

                if (resp.status === 404) {
                    alert(resp.data);
                    return;
                }

                console.log(err);
                alert("erro");

                return;
            });
    };

    const requerirSenha = () => {
        let email = window.prompt("Insira o seu email para requisitar uma nova senha:");
        if (!email) return;

        let confirmacao = window.confirm(`Deseja realmente uma nova senha para o email: ${email}?`);
        if (!confirmacao) return;

        Api.PostNovaSenha(email)
            .then((res) => {
                alert("Sua nova senha foi enviada para o email informado!");
            })
            .catch((err) => {
                alert("Houve um erro. Contate a administração do sistema.");
            });
    };

    return (
        <div id="loginPage">
            <h1>LOGIN</h1>

            <form onSubmit={submitLogin}>
                <label>
                    <input
                        placeholder="Usuário"
                        type={"email"}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        required
                    />
                </label>
                <label>
                    <input
                        placeholder="Senha"
                        type={"password"}
                        value={senha}
                        onChange={(e) => {
                            setSenha(e.target.value);
                        }}
                        required
                    />
                </label>

                <button className="novaSenhaBtn" type="button" onClick={requerirSenha}>
                    Requerir nova senha
                </button>

                <button type="submit">ENTRAR</button>
            </form>

            <img src={LogoDirecty} alt="Logo Mosaic" />
        </div>
    );
}

export default Login;
