import "./styles.css";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Cabecalho from "../../common/components/cabecalho";
import Rodape from "../../common/components/rodape";

function BaseHome() {
    const navegar = useNavigate();

    const [usuario, setUsuario] = useState();

    useEffect(() => {
        try {
            let loginCandidato = sessionStorage.getItem("loginCandidato");

            if (!loginCandidato) {
                throw new Error("Login não registrado");
            }

            let obj_loginCandidato = JSON.parse(loginCandidato);
            if (typeof obj_loginCandidato != "object") {
                throw new Error("Login salvo inválido");
            }

            setUsuario(obj_loginCandidato);
        } catch (err) {
            console.log(err);
            navegar("/login");
        }
    }, [navegar]);

    return (
        <div id="baseHomePage">
            <Cabecalho usuario={usuario} />

            <div className="baseHomePageOutlet">
                <Outlet />
            </div>

            <Rodape />
        </div>
    );
}

export default BaseHome;
