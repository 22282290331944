import "./styles.css";
import React from "react";
import instagramIcon from "../../../assets/images/instagramIcon.png";
import linkedinIcon from "../../../assets/images/linkedinIcon.png";
import { useTranslation } from "react-i18next";

function Rodape() {
    const { t } = useTranslation();

    return (
        <div id="rodapeComponent">
            <span>© 2023 Directy, {t("todos os direitos reservados")}</span>

            <div>
                <a
                    href="https://www.instagram.com/directyconsulting/"
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <img
                        src={instagramIcon}
                        alt={t("icone do rede verde", { rede: "instagram" })}
                    />
                </a>
                <a
                    href="https://www.linkedin.com/company/directy"
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <img src={linkedinIcon} alt={t("icone do rede verde", { rede: "linkedin" })} />
                </a>
            </div>
        </div>
    );
}

export default Rodape;
