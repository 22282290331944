import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./styles.css";

function TelaHome() {
    const { t } = useTranslation();

    return (
        <div id="TelaHome">
            <Link to={"/desafios"}>{t("Desafios")}</Link>
            <Link to={"/perfil"}>{t("Perfil")}</Link>
        </div>
    );
}

export default TelaHome;
