import "./styles.css";

function TelaPerfil() {
    return (
        <div id="TelaPerfil">
            <h2>Em construção.</h2>
        </div>
    );
}

export default TelaPerfil;
